// app/javascript/controllers/column_toggle_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["column", "cell", "toggleButton", "toggleHeader"]

  connect() {
    this.updateColumnWidths()
  }

  hideColumn(event) {
    event.preventDefault()
    const index = event.currentTarget.dataset.index

    // Masquer la cellule d'en-tête
    this.columnTargets.forEach(column => {
      if (column.dataset.index === index) {
        column.classList.add("hidden-column")
        column.dataset.hidden = "true"
      }
    })

    // Masquer les cellules de données
    this.cellTargets.forEach(cell => {
      if (cell.dataset.index === index) {
        cell.classList.add("hidden-column")
        cell.dataset.hidden = "true"
      }
    })

    // Afficher le bouton d'affichage correspondant
    this.toggleButtonTargets.forEach(button => {
      if (button.dataset.index === index) {
        button.style.display = 'inline-block' // Utilisez 'inline-block' pour que le bouton s'affiche correctement
      }
    })

    // Mettre à jour les largeurs de colonnes
    this.updateColumnWidths()
  }

  showColumn(event) {
    event.preventDefault()
    const index = event.currentTarget.dataset.index

    // Afficher la cellule d'en-tête
    this.columnTargets.forEach(column => {
      if (column.dataset.index === index) {
        column.classList.remove("hidden-column")
        column.dataset.hidden = "false"
      }
    })

    // Afficher les cellules de données
    this.cellTargets.forEach(cell => {
      if (cell.dataset.index === index) {
        cell.classList.remove("hidden-column")
        cell.dataset.hidden = "false"
      }
    })

    // Masquer le bouton d'affichage correspondant
    this.toggleButtonTargets.forEach(button => {
      if (button.dataset.index === index) {
        button.style.display = 'none'
      }
    })

    // Mettre à jour les largeurs de colonnes
    this.updateColumnWidths()
  }

  updateColumnWidths() {
    // Obtenir le nombre de colonnes visibles
    const visibleColumns = this.columnTargets.filter(column => column.dataset.hidden !== "true")
    const columnCount = visibleColumns.length

    // Vérifier pour éviter la division par zéro
    if (columnCount === 0) return

    // Calculer la largeur en pourcentage pour chaque colonne
    const widthPercentage = 100 / columnCount

    // Appliquer la largeur à chaque cellule d'en-tête visible
    this.columnTargets.forEach(column => {
      if (column.dataset.hidden !== "true") {
        column.style.width = `${widthPercentage}%`
      }
    })

    // Appliquer la largeur à chaque cellule de données visible
    this.cellTargets.forEach(cell => {
      if (cell.dataset.hidden !== "true") {
        cell.style.width = `${widthPercentage}%`
      }
    })

    // Appliquer la largeur aux cellules de la ligne des boutons d'affichage
    this.toggleHeaderTargets.forEach(header => {
      header.style.width = `${widthPercentage}%`
    })
  }
}
